import "./ProfileUpdate.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAllData, putData } from "../../Api/api";
import Button from "react-bootstrap-button-loader";
const ProfileUpdate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const getData = () => {
    getAllData({
      url: "shopkeeper/getProfile",
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: null,
    country: "",
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(data);
    setFormData({
      ...formData,
      firstName: data?.data?.firstName,
      lastName: data?.data?.lastName,
      email: data?.data?.email,
      country: data?.data?.country,
    });

    if (data?.data?.profilePic) {
      setImagePreview(data?.data?.profilePic);
    }
  }, [data]);

  const [img, setImg] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/svg+xml" || file.type.startsWith("image/"))
    ) {
      setImg(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (
      file &&
      (file.type === "image/svg+xml" || file.type.startsWith("image/"))
    ) {
      setImg(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append("firstName", formData.firstName);
    payload.append("lastName", formData.lastName);
    payload.append("email", formData.email);
    payload.append("country", formData.country);
    if (img) payload.append("image", img);

    putData({
      url: "shopkeeper/updateProfile",
      payload,
      setIsLoading,
      navigate,
      additionalFunc: () => navigate("/profile"),
    });
  };
  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Profile Update</h6>
            </div>
          </div>

          <div className="profileupdate">
            <div className="profileupdate1">
              <div className="profileupdate2">
                <div className="profileupdate3">
                  <label htmlFor="">First Name</label>
                  <label htmlFor="">Last Name</label>
                  <label htmlFor="">Email</label>
                  <label htmlFor="">
                    Your Photo <br />{" "}
                    <p>This photo will be displayed on your profile</p>
                  </label>
                  <label htmlFor="">Country</label>
                </div>

                <div className="profileupdate4">
                  <input
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    value={formData.firstName}
                    placeholder=" First Name"
                  />
                  <input
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    value={formData.lastName}
                    placeholder="Last Name"
                  />

                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    placeholder="Email"
                  />

                  <div className="profileupdate6">
                    <div className="profileupdate7">
                      {previewUrl ? (
                        <img style={{width: "100px", height: "100px"}} src={previewUrl} alt="Uploaded preview" />
                      ) : imagePreview ? (
                        <img style={{width: "100px", height: "100px"}} src={imagePreview} alt="Uploaded preview" />
                      ) : (
                        <p>No image uploaded</p>
                      )}
                    </div>
                    <div
                      className="profileupdate8"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      <div className="profileupdate9">
                        <p>
                          Click to upload or <span>drag & drop</span>
                          <br />
                          (SVG, PNG, JPG, and JPEG)
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/svg+xml, image/png, image/jpeg"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>

                  <input
                    type="text"
                    name="country"
                    onChange={handleChange}
                    value={formData.country}
                    placeholder="India"
                  />
                </div>
              </div>
            </div>

            <div className="kyc5">
              <button onClick={() => navigate("/dashboard")}>Cancel</button>
              <Button loading={isLoading} onClick={handleSubmit}>
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(ProfileUpdate);
